exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/CaseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-category-tsx": () => import("./../../../src/templates/PostCategory.tsx" /* webpackChunkName: "component---src-templates-post-category-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/Service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-vertical-tsx": () => import("./../../../src/templates/Vertical.tsx" /* webpackChunkName: "component---src-templates-vertical-tsx" */)
}

